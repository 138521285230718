import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import Loading from "components/shared/Loading";
import { useAuth } from "hooks/useAuth";
import { Links } from "configs/LinkConfig";
import { PrivateRoute } from "components/util/PrivateRoute";

export const AppRoutes = () => {
  const location = useLocation();
  const {
    authState: { isSignedIn },
    examMode,
  } = useAuth();

  if (!isSignedIn) {
    return (
      <Redirect
        to={{ pathname: Links.auth.login, state: { from: location.pathname } }}
      />
    );
  }

  if (examMode) {
    return (
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <PrivateRoute
            path={Links.app.contests.root}
            component={lazy(() => import("./contests/ContestsRoutes"))}
            requiredRole={["professor", "superuser", "contestant", "staff"]}
          />
          <Redirect to={Links.app.contests.root} />
        </Switch>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivateRoute
          path={Links.app.users.root}
          component={lazy(() => import("./users/UsersRoutes"))}
          requiredRole={["professor", "superuser"]}
        />
        <PrivateRoute
          path={Links.app.contests.root}
          component={lazy(() => import("./contests/ContestsRoutes"))}
          requiredRole={["professor", "superuser", "contestant", "staff"]}
        />
        <PrivateRoute
          path={Links.app.problems.root}
          component={lazy(() => import("./problems/ProblemsRoutes"))}
          requiredRole={["professor", "superuser"]}
        />
        <PrivateRoute
          path={Links.app.tags.root}
          component={lazy(() => import("./tags/TagsRoutes"))}
          requiredRole={["professor", "superuser"]}
        />
        <PrivateRoute
          path={Links.app.courseSubjects.root}
          component={lazy(() => import("./courseSubjects/CourseSubjectsRoutes"))}
          requiredRole={["professor", "superuser"]}
        />
        <PrivateRoute
          path={Links.app.semesters.root}
          component={lazy(() => import("./semesters/SemestersRoutes"))}
          requiredRole={["professor", "superuser"]}
        />
        <PrivateRoute
          path={Links.app.classes.root}
          component={lazy(() => import("./classes/ClassesRoutes"))}
          requiredRole={["professor", "superuser", "staff"]}
        />
        <PrivateRoute
          path={Links.app.submissions.root}
          component={lazy(() => import("./submissions/SubmissionsRoutes"))}
          requiredRole={["professor", "superuser", "contestant"]}
        />

        <PrivateRoute
          path={Links.app.gradeReport.root}
          component={lazy(() => import("./gradeReport/GradeReportRoutes"))}
          requiredRole={["professor", "superuser", "contestant"]}
        />

        <PrivateRoute
          path={Links.app.material.root}
          component={lazy(() => import("./materials/MaterialsRoutes"))}
          requiredRole={["professor", "superuser", "contestant"]}
        />

        <PrivateRoute
          path={Links.app.dashboard.root}
          component={lazy(() => import("./dashboard/JudeDashboard"))}
          requiredRole={["professor", "superuser"]}
        />

        <PrivateRoute
          path={Links.app.dashboard.professor}
          component={lazy(() => import("./dashboard/JudeDashboard"))}
          requiredRole={["professor", "superuser"]}
        />

        <PrivateRoute
          path={Links.app.dashboard.contestant}
          component={lazy(() => import("./dashboard/JudeDashboard"))}
          requiredRole={["contestant"]}
        />

        <PrivateRoute
          path={Links.app.adminConsole.root}
          component={lazy(() => import("./admin/AdminConsole"))}
          requiredRole={["professor", "superuser"]}
        />

        <Route
          path={Links.app.changePassword}
          component={lazy(() => import("./profile/ChangePasswordScreen"))}
        />

        <Route path={Links.app.home} component={lazy(() => import("./home"))} />
        <Redirect to={Links.app.home} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppRoutes);

import React, { ComponentType, useEffect, useState } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Links } from "configs/LinkConfig";
import { notification } from "antd";
import Loading from "components/shared/Loading";
import { api } from "api";
import { UserRole } from "contexts/AuthContext";

export interface Props extends RouteProps {
  component: ComponentType;
  requiredRole: UserRole[];
}

export const PrivateRoute = ({
  component: Component,
  requiredRole,
  ...rest
}: Props) => {
const [authorized, setAuthorized] = useState(false);
const [permissionsLoaded, setPermissionsLoaded] = useState(false);

useEffect(() => {
  const checkPermissions = async () => {
    try {
      const isSuperuser = requiredRole.includes("superuser") && (await api.user.checksuperuser()).data.is_superuser;
      const isProfessor = requiredRole.includes("professor") && (await api.user.checkprofessor()).data.is_professor;
      const isStaff = requiredRole.includes("staff") && (await api.user.checkstaff()).data.is_staff;
      const isContestant = requiredRole.includes("contestant") && (await api.user.checkcontestant()).data.is_contestant;

      setAuthorized(isSuperuser || isProfessor || isStaff || isContestant);
    } catch (error) {
      notification.error({
        message: "Error checking permissions",
        description: (error as any).message,
      });
    } finally {
      setPermissionsLoaded(true);
    }
  };

  checkPermissions();
}, [requiredRole]);

if (!permissionsLoaded) {
  return <Loading cover="content" />;
}

return (
  <Route
    {...rest}
    render={() => {
      if (authorized) {
        return <Component />;
      }

      return <Redirect to={Links.app.home} />;
    }}
  />
);
};

export const Links = {
  root: "/",
  app: {
    root: "/app",
    home: "/app/home",
    changePassword: "/app/change-password",
    contests: {
      root: "/app/contests",
      create: "/app/contests/create",
      edit: "/app/contests/edit",
      details: (id: number | string) => `/app/contests/${id}`,
    },
    users: {
      root: "/app/users",
      batch: "/app/users/batch",
      create: "/app/users/create",
      details: (id: number | string) => `/app/users/${id}`,
      edit: (id: number | string) => `/app/users/edit/${id}`,
    },
    problems: {
      root: "/app/problems",
      create: "/app/problems/create",
      details: (id: number | string) => `/app/problems/${id}`,
      edit: (id: number | string) => `/app/problems/edit/${id}`,
    },
    tags: {
      root: "/app/tags",
      create: "/app/tags/create",
      details: (id: number | string) => `/app/tags/${id}`,
      edit: (id: number | string) => `/app/tags/edit/${id}`,
    },
    courseSubjects: {
      root: "/app/courseSubjects",
      create: "/app/courseSubjects/create",
      details: (id: number | string) => `/app/courseSubjects/${id}`,
      edit: (id: number | string) => `/app/courseSubjects/edit/${id}`,
    },
    semesters: {
      root: "/app/semesters",
      create: "/app/semesters/create",
      details: (id: number | string) => `/app/semesters/${id}`,
      edit: (id: number | string) => `/app/semesters/edit/${id}`,
    },
    submissions: {
      root: "/app/submissions",
      create: "/app/submissions/create",
      submit: (contestId: number | string, problemId: number | string) =>
        `/app/submissions/submit/${contestId}/${problemId}`,
    },
    gradeReport: {
      root: "/app/grade-report",
      create: "/app/grade-report/create",
      edit: (id: number) => `/app/grade-report/${id}`,
      generate: (id: number) => `/app/grade-report/${id}/generate`,
    },
    material: {
      root: "/app/material",
      create: "/app/material/create",
      details: "/app/material/:id/generate",
      edit: (id: number) => `/app/material/${id}`,
    },
    classes: {
      root: "/app/classes",
      create: "/app/classes/create",
      details: (id: number | string) => `/app/classes/${id}`,
      edit: (id: number | string) => `/app/classes/edit/${id}`,
    },  
    dashboard: {
      root: "/app/dashboard/main",
      subjects: "app/dashboard/main/subjects",
      professor: "/app/dashboard/professor",
      contestant: "/app/dashboard/contestant",
    },
    adminConsole: {
      root: "/app/admin",
    },
  },
  auth: {
    root: "/auth",
    login: "/auth/login",
    resetPassword: "/auth/reset-password/:token",
  },
} as const;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ParticipantRead {
  /** Username */
  username?: string;
  /** First name */
  first_name?: string;
  /** Last name */
  last_name?: string;
  /** Email */
  email?: string;
}

export interface ProblemRead {
  /** Id */
  id?: string;
  /**
   * Letter
   * @maxLength 8
   */
  letter?: string | null;
  /**
   * Color
   * @maxLength 8
   */
  color?: string | null;
  /** Status */
  status?: string;
  /** Post submit */
  post_submit?: string;
  /** Name */
  name?: string;
  /** Visibility */
  visibility?: string;
  /** Time */
  time?: string;
  /** Memory */
  memory?: string;
  /** Assertiveness index */
  assertivenessindex?: number;
}
export interface TaskRead {
  id?: number;
  task_type: string;
  contest: ContestRead;
  submission: Submission;
  username: string;
  user_full_name: string;
  problem_letter: string;
  problem_color: string;
  is_done: boolean;
  /**
   * Created
   * @format date-time
   */
  created?: string;
}
export interface TaskWrite {
  id?: number;
  task_type: string;
  contest_id: number;
  submission_id: number;
  username?: string;
  user_full_name: string;
  problem_letter: string;
  problem_color: string;
  is_done: boolean;
}
export interface MaterialContent {
  /**
   * Name
   * The name of material content
   * @minLength 1
   * @maxLength 256
   */
  name: string;
  /**
   * The link to an external content
   * @format uri
   * @minLength 1
   * @maxLength 200
   */
  link: string;
}

export interface Material {
  /** ID */
  id?: number;
  /**
   * Name
   * The name of material
   * @minLength 1
   * @maxLength 256
   */
  name: string;
  /**
   * Course
   * The course of this Material
   * @minLength 1
   * @maxLength 256
   */
  course: string;
  contents: MaterialContent[];
  /** Creator */
  creator?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
}

export interface ContestRead {
  /**
   * Name
   * The name of Contest
   * @minLength 1
   * @maxLength 256
   */
  name: string;
  /**
   * Start time
   * The exact moment of the start of contest
   * @format date-time
   */
  start_time: string;
  /**
   * End time
   * The exact moment of the end of contest
   * @format date-time
   */
  end_time: string;
  /**
   * Hidden
   * Determines whether the contest is visible. Select this instead of deleting Contest.
   */
  hidden?: boolean;
  /**
   * Upseeing
   * Users can see codes of another users after the competition
   */
  upseeing?: boolean;
  /**
   * Time to blind (minutes)
   * Determines the number of minutes that the user will not receive feedback.
   * @min -2147483648
   * @max 2147483647
   */
  blind?: number;
  /**
   * Unique Session
   * Determines whether the user only can access in a unique session when the contest is running with type EXAM
   */
  unique_session?: boolean;
  /**
   * Hide Others
   * When this is true, the user can't see others contests and materials
   */
  hide_others?: boolean;
  /**
   * Threshold of similarity
   * Determines the percentage of plagiarism to be tolerated when displaying plagiarism.
   * @min 0
   * @max 2147483647
   */
  similarity?: number;
  /**
   * Time of freeze (minutes)
   * Determines the number of minutes the scoreboard will be frozen.
   * @min -2147483648
   * @max 2147483647
   */
  freeze?: number;
  /**
   * Type of scoring
   * There are some types of scoring. Choose one.
   */
  scoring?: 0 | 1 | 2 | 3;
  /**
   * Type of contest
   * When your choose EXAM, all other contest aren't visible by user when the current contest is running
   */
  contest_type?: 0 | 1;
  users?: ParticipantRead[];
  problems?: ProblemRead[];
  /** Progress */
  progress?: string;
  materials?: Material[];
  programming_languages: string;
  /** ID */
  id: number;
}

export interface ParticipantWrite {
  /**
   * Username
   * @minLength 1
   */
  username: string;
}

export interface ProblemWrite {
  /** Id */
  id: number;
  /**
   * Letter
   * @maxLength 8
   */
  letter?: string | null;
  /**
   * Color
   * @maxLength 8
   */
  color?: string | null;
  /** Status */
  status?: string;
  /** Post submit */
  post_submit?: string;
  /** Name */
  name?: string;
  /** Visibility */
  visibility?: string;
  /** Time */
  time?: string;
  /** Memory */
  memory?: string;
}

export interface ContestMaterial {
  /** Id */
  id: number;
}

export interface ContestWrite {
  /**
   * Name
   * The name of Contest
   * @minLength 1
   * @maxLength 256
   */
  name: string;
  /**
   * Start time
   * The exact moment of the start of contest
   * @format date-time
   */
  start_time: string;
  /**
   * End time
   * The exact moment of the end of contest
   * @format date-time
   */
  end_time: string;
  /**
   * Hidden
   * Determines whether the contest is visible. Select this instead of deleting Contest.
   */
  hidden?: boolean;
  /**
   * Upseeing
   * Users can see codes of another users after the competition
   */
  upseeing?: boolean;
  /**
   * Time to blind (minutes)
   * Determines the number of minutes that the user will not receive feedback.
   * @min -2147483648
   * @max 2147483647
   */
  blind?: number;
  /**
   * Unique Session
   * Determines whether the user only can access in a unique session when the contest is running with type EXAM
   */
  unique_session?: boolean;
  /**
   * Hide Others
   * When this is true, the user can't see others contests and materials
   */
  hide_others?: boolean;
  /**
   * Threshold of similarity
   * Determines the percentage of plagiarism to be tolerated when displaying plagiarism.
   * @min 0
   * @max 2147483647
   */
  similarity?: number;
  /**
   * Time of freeze (minutes)
   * Determines the number of minutes the scoreboard will be frozen.
   * @min -2147483648
   * @max 2147483647
   */
  freeze?: number;
  /**
   * Type of scoring
   * There are some types of scoring. Choose one.
   */
  scoring?: 0 | 1 | 2 | 3;
  /**
   * Type of contest
   * When your choose EXAM, all other contest aren't visible by user when the current contest is running
   */
  contest_type?: 0 | 1;
  users: ParticipantWrite[];
  problems: ProblemWrite[];
  /** Progress */
  progress?: string;
  materials?: ContestMaterial[];
  /** ID */
  id?: number;
}

export interface StandingsProblems {
  /**
   * Id
   * @min -2147483648
   * @max 2147483647
   */
  id: number;
  /**
   * Color
   * @minLength 1
   * @maxLength 16
   */
  color: string;
  /**
   * Value
   * @min 0
   * @max 2147483647
   */
  value?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 16
   */
  name: string;
  /**
   * Letter
   * @minLength 1
   * @maxLength 16
   */
  letter: string;
}

export interface Standings {
  /**
   * Username
   * @minLength 1
   * @maxLength 255
   */
  username: string;
  fullname: string;
  /**
   * Score
   * @min -2147483648
   * @max 2147483647
   */
  score: number;
  /**
   * Total
   * @min -2147483648
   * @max 2147483647
   */
  total: number;
  problems: StandingsProblems[];
}

export interface Submission {
  /** ID */
  id?: number;
  /**
   * Registration date
   * @format date-time
   */
  time?: string;
  /**
   * Language
   * @minLength 1
   * @maxLength 32
   */
  language: string;
  /** Language name */
  language_name?: string;
  /** Problem */
  problem: number;
  /** Problem name */
  problem_name?: string;
  /** Verdict */
  verdict?: string;
  _verdict?: string;
  /**
   * Post Submit
   * Determines whether the submission was after the end of the Contest.
   */
  post_submit?: boolean;
  /** Duration */
  duration?: string;
  /** Participant */
  participant?: string;
  submited_code?: string;
}

export interface CourseSubject {
  /**
   * Subject name
   * @minLength 1
   * @maxLength 128
   */
  name: string;
  /**
   * Subject code
   * @minLength 1
   * @maxLength 8
   */
  code: string;
}

export interface ContestSemester {
  /**
   * Subject name
   * @minLength 1
   */
  subject_name: string;
  /**
   * Contest semester
   * @minLength 1
   */
  contest_semester: string;
  /**
   * Total
   * @min -2147483648
   * @max 2147483647
   */
  total: number;
}

export interface DashboardCount {
  /**
   * Participant
   * @min -2147483648
   * @max 2147483647
   */
  participant: number;
  /**
   * Professor
   * @min -2147483648
   * @max 2147483647
   */
  professor: number;
  /**
   * Contest
   * @min -2147483648
   * @max 2147483647
   */
  contest: number;
  /**
   * Problem
   * @min -2147483648
   * @max 2147483647
   */
  problem: number;
  /**
   * Coursesubject
   * @min -2147483648
   * @max 2147483647
   */
  coursesubject: number;
  /**
   * Average final grade
   * @format decimal
   */
  average_final_grade: string;
}

export interface SemesterSubmission {
  /**
   * Semester
   * @minLength 1
   */
  semester: string;
  /**
   * Submission type
   * @minLength 1
   */
  submission_type: string;
  /**
   * Total
   * @min -2147483648
   * @max 2147483647
   */
  total: number;
}

export interface SubmissionTag {
  /**
   * Tag
   * @minLength 1
   */
  tag: string;
  /**
   * Submission type
   * @minLength 1
   */
  submission_type: string;
  /**
   * Total
   * @min -2147483648
   * @max 2147483647
   */
  total: number;
}

export interface SubmissionType {
  /**
   * Submission type
   * @minLength 1
   */
  submission_type: string;
  /**
   * Total
   * @min -2147483648
   * @max 2147483647
   */
  total: number;
}

export interface GradeReport {
  /** ID */
  id?: number;
  /**
   * Course
   * @minLength 1
   * @maxLength 255
   */
  course: string;
  /**
   * Semester
   * @minLength 1
   * @maxLength 64
   */
  semester: string;
  /** Created by */
  created_by?: string;
  /** Report structure */
  report_structure?: object | null;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
}

export interface Languages {
  /**
   * Id
   * @minLength 1
   */
  id: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
}

export interface ChangePassword {
  /**
   * Old password
   * @minLength 1
   */
  old_password: string;
  /**
   * New password
   * @minLength 1
   */
  new_password: string;
}

export interface ChangePasswordByProfessor {
  /**
   * Password
   * @minLength 1
   */
  password: string;
  /**
   * Username
   * @minLength 1
   */
  username: string;
}

export interface ResetPassword {
  /**
   * Username
   * @minLength 1
   */
  username: string;
}

export interface CreateNewPassword {
  /**
   * Password
   * @minLength 1
   */
  password: string;
  /**
   * Token
   * @minLength 1
   */
  token?: string;
}

export interface BaseProblem {
  /** Difficulty of problem */
  difficulty?: "1" | "2" | "3" | "4" | "5" | null;
  /**
   * Name of problem
   * @minLength 1
   * @maxLength 256
   */
  name: string;
  /** Tag */
  tag: number;
  /**
   * Time in milliseconds
   * @min -2147483648
   * @max 2147483647
   */
  time?: number;
  /**
   * Memory allocated in MB
   * @min -2147483648
   * @max 2147483647
   */
  memory?: number;
  /** ID */
  id?: number;
  /**
   * Testscase
   * @format uri
   */
  testscase?: string;
  /** Assertiveness index */
  assertivenessindex?: number;
}

export interface Statement {
  /** ID */
  id?: number;
  /**
   * Statement
   * A PDF file with statement of this problem
   * @format uri
   */
  statement?: string;
}

export interface Testcase {
  /** ID */
  id?: number;
  /**
   * Testscase
   * Submit a zip file with testscase of this problem
   * @format uri
   */
  testscase?: string;
}

export interface JudeSessionLog {
  /** ID */
  id?: number;
  /**
   * Username
   * @minLength 1
   * @maxLength 255
   */
  username: string;
  /**
   * IP Address
   * @minLength 1
   */
  ip_address: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Jude Session Log
   * @minLength 1
   * @maxLength 2048
   */
  token: string;
  /**
   * Revoked at
   * @format date-time
   */
  revoked_at?: string | null;
  /** Revoked by */
  revoked_by?: number | null;
  /** Contest */
  contest?: string;
}

export interface SimilarityDetector {
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /**
   * Result
   * @min 0
   * @max 100
   */
  result: number;
}

export interface Similarity {
  /** Id */
  id: number;
  /**
   * Username1
   * @minLength 1
   */
  username1: string;
  /**
   * Name1
   * @minLength 1
   */
  name1: string;
  /**
   * Username2
   * @minLength 1
   */
  username2: string;
  /**
   * Name2
   * @minLength 1
   */
  name2: string;
  /** Confirmed by teacher */
  confirmed_by_teacher?: boolean | null;
  detectors: SimilarityDetector[];
}

export interface SimilarityCode {
  /**
   * Username
   * @minLength 1
   */
  username: string;
  /**
   * Code
   * @minLength 1
   */
  code: string;
}

export interface SimilarityProblem {
  /** Id */
  id: number;
  /**
   * Language
   * @minLength 1
   */
  language: string;
  sources: SimilarityCode[];
  detectors: SimilarityDetector[];
}

export interface SimilarityConfirm {
  /**
   * @maxItems 2
   * @minItems 2
   */
  usernames: string[];
  /** Confirm */
  confirm: boolean;
}

export interface Tag {
  /** ID */
  id?: number;
  /**
   * Tag name
   * @minLength 1
   * @maxLength 64
   */
  name: string;
}
export interface CourseSubject {
  /** ID */
  id: number;
  /**
   * CourseSubject name
   * @minLength 1
   * @maxLength 64
   */
  name: string;
  code: string;
}
export interface Semester {
  /** ID */
  id: number;
  /**
   * Semester name
   * @minLength 1
   * @maxLength 64
   */
  code: string;
}

export interface JSONWebToken {
  /**
   * Password
   * @minLength 1
   */
  password: string;
  /**
   * Token
   * @minLength 1
   */
  token?: string;
  /**
   * Username
   * @minLength 1
   */
  username: string;
}

export interface RefreshAuthToken {
  /**
   * Token
   * @minLength 1
   */
  token: string;
}

export interface User {
  /**
   * Username
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @minLength 1
   * @maxLength 150
   * @pattern ^[\w.@+-]+$
   */
  username: string;
  /**
   * First name
   * @maxLength 150
   */
  first_name?: string;
  /**
   * Last name
   * @maxLength 150
   */
  last_name?: string;
  /**
   * Is active
   * @default true
   */
  is_active?: boolean;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * Staff status
   */
  is_staff?: boolean;
  /**
   * admin status
   */
  is_superuser?: boolean;
  /**
   * professor status
   */
  is_professor?: boolean;
  /**
   * contestant status
   */
  is_contestant?: boolean;
  /** ID */
  id: number;
  /**
   * Password1
   * @minLength 1
   */
  password1?: string;
  /**
   * Password2
   * @minLength 1
   */
  password2?: string;
  role: string;
}

export interface CheckSurveyAnswer {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
}

export interface HasSurveyAnswer {
  /** Hasanswered */
  hasAnswered: boolean;
}

export interface UserBatch {
  /** Username */
  username: number;
  /**
   * First name
   * @maxLength 150
   */
  first_name?: string;
  /**
   * Last name
   * @maxLength 150
   */
  last_name?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
}

export interface Classs {
  id: number;
  name: string;
  subject: CourseSubject;
  semester: Semester;
}

export interface ClasssUser {
  id?: number;
  classs: number;
  user?: User;
  username: string;
}

export interface ClasssWrite {
  name: string;
  subject_id: number;
  semester_id: number;
}

export interface TestcaseResult {
  id?: number;
  submission: number;
  problem: number;
  problem_test_case: number;
  participant: number;
  passed: boolean;
  free_to_see: boolean;
  error_message?: string;
  created?: string;
  updated?: string;
}

export interface TestcaseResultCreate {
  submission: number;
  problem: number;
  problem_test_case: number;
  participant: number;
  passed: boolean;
  free_to_see: boolean;
  error_message?: string;
}

export interface TestcaseResultList {
  count: number;
  next?: string;
  previous?: string;
  results: TestcaseResult[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://localhost:8000/api/v1";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string"
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }


  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        signal: cancelToken
          ? this.createAbortSignal(cancelToken)
          : requestParams.signal,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
          .then((data) => {
            if (r.ok) {
              r.data = data;
            } else {
              r.error = data;
            }
            return r;
          })
          .catch((e) => {
            r.error = e;
            return r;
          });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Jude 2.0 API
 * @version v1
 * @license BSD License
 * @termsOfService https://www.google.com/policies/terms/
 * @baseUrl http://localhost:8000/api/v1
 * @contact <moreiralucascc@gmail.com>
 *
 * This API reference show some endpoints of Jude API
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {

  class = {
    /**
   * @description Class CRUD
   *
   * @tags class
   * @name ClassCreate
   * @request POST:/classes/
   * @secure
   */
    classCreate: (data: ClasssWrite, params: RequestParams = {}) =>
      this.request<ClasssWrite, any>({
        path: `/classes/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Class CRUD
     *
     * @tags class
     * @name ClassRead
     * @request GET:/classes/{id}/
     * @secure
     */
    classRead: (id: number, params: RequestParams = {}) =>
      this.request<Classs, any>({
        path: `/classes/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Class CRUD
     *
     * @tags class
     * @name ClassList
     * @request GET:/classes/
     * @secure
     */

    classList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Classs[];
        },
        any
      >({
        path: `/classes/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Class CRUD
     *
     * @tags class
     * @name ClassUpdate
     * @request PUT:/classes/{id}/
     * @secure
     */
    classUpdate: (id: number, data: ClasssWrite, params: RequestParams = {}) =>
      this.request<ClasssWrite, any>({
        path: `/classes/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Class CRUD
     *
     * @tags class
     * @name ClassDelete
     * @request DELETE:/classes/{id}/
     * @secure
     */
    classDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/classes/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  }

  classUser = {
    /**
   * @description Class CRUD
   *
   * @tags class
   * @name ClassCreate
   * @request POST:/classes/
   * @secure
   */
    classUserCreate: (data: ClasssUser, params: RequestParams = {}) =>
      this.request<ClasssUser, any>({
        path: `/classesUser/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),


    /**
     * @description Class CRUD
     *
     * @tags class
     * @name ClassList
     * @request GET:/classes/
     * @secure
     */

    classUserList: (
      query?: {
        /** A search term. */
        search?: string;
        /** A search term. */
        classId?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: {
            class_users: ClasssUser[];
          };
        },
        any
      >({
        path: `/classesUser/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * @description Class CRUD
     *
     * @tags class
     * @name ClassDelete
     * @request DELETE:/classes/{id}/
     * @secure
     */
    classUserDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/classesUser/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),  /**
    * @description Class CRUD
    *
    * @tags class
    * @name ClassDelete
    * @request DELETE:/classes/{id}/
    * @secure
    */
    classUserDeleteByUser: (classId: number, user: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/classesUser/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  }
  task = {
    /**
     * @description List tasks
     *
     * @tags task
     * @name TaskList
     * @request GET:/tasks/
     * @secure
     */
    taskList: (
      query?: {
      /** A search term. */
      search?: string;
      /** Which field to use when ordering the results. */
      ordering?: string;
      /** contest */
      contest?: number;
      /** A page number within the paginated result set. */
      page?: number;
      /** Number of results to return per page. */
      page_size?: number;
    },
    params: RequestParams = {}
  ) =>
    this.request<
      {
        count: number;
        /** @format uri */
        next?: string | null;
        /** @format uri */
        previous?: string | null;
        results: TaskRead[];
      },
      any
    >({
      path: `/tasks/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
      }),
  
    /**
     * @description Create Task
     *
     * @tags task
     * @name TaskCreate
     * @request POST:/tasks/
     * @secure
     */
    taskCreate: (data: TaskWrite, params: RequestParams = {}) =>
      this.request<void, any>({
      path: `/tasks/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
      }),
  
    /**
     * @description Update Task
     *
     * @tags task
     * @name TaskUpdate
     * @request PUT:/tasks/{id}/
     * @secure
     */
    taskUpdate: (id: number, data:{is_done: boolean}, params: RequestParams = {}) =>
      this.request<void, any>({
      path: `/tasks/${id}/`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
      }),
  
    /**
     * @description Delete Task
     *
     * @tags task
     * @name TaskDelete
     * @request DELETE:/tasks/{id}/
     * @secure
     */
    taskDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
      path: `/tasks/${id}/`,
      method: "DELETE",
      secure: true,
      ...params,
      }),
  }
  contest = {
    /**
     * @description Contest ViewSet
     *
     * @tags contest
     * @name ContestList
     * @request GET:/contest/
     * @secure
     */
    contestList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** name */
        name?: string;
        /** start_time */
        start_time?: string;
        /** end_time */
        end_time?: string;
        /** similarity */
        similarity?: string;
        /** freeze */
        freeze?: string;
        /** scoring */
        scoring?: string;
        /** contest_type */
        contest_type?: "exam" | "training";
        /** status */
        status?: "ongoing" | "past";
        /** subject_id */
        subject_id?: string;
        /** id */
        id?: string;
        /** semester */
        semester?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ContestRead[];
        },
        any
      >({
        path: `/contest/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Contest ViewSet
     *
     * @tags contest
     * @name ContestCreate
     * @request POST:/contest/
     * @secure
     */
    contestCreate: (data: ContestWrite, params: RequestParams = {}) =>
      this.request<ContestWrite, any>({
        path: `/contest/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get current contests
     *
     * @tags contest
     * @name ContestCurrent
     * @request GET:/contest/current/
     * @secure
     */
    contestCurrent: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** name */
        name?: string;
        /** start_time */
        start_time?: string;
        /** end_time */
        end_time?: string;
        /** similarity */
        similarity?: string;
        /** freeze */
        freeze?: string;
        /** scoring */
        scoring?: string;
        /** contest_type */
        contest_type?: "exam" | "training";
        /** status */
        status?: "ongoing" | "past";
        /** subject_id */
        subject_id?: string;
        /** id */
        id?: string;
        /** semester */
        semester?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ContestRead[];
        },
        any
      >({
        path: `/contest/current/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get past contests
     *
     * @tags contest
     * @name ContestPast
     * @request GET:/contest/past/
     * @secure
     */
    contestPast: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** name */
        name?: string;
        /** start_time */
        start_time?: string;
        /** end_time */
        end_time?: string;
        /** similarity */
        similarity?: string;
        /** freeze */
        freeze?: string;
        /** scoring */
        scoring?: string;
        /** contest_type */
        contest_type?: "exam" | "training";
        /** status */
        status?: "ongoing" | "past";
        /** subject_id */
        subject_id?: string;
        /** id */
        id?: string;
        /** semester */
        semester?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ContestRead[];
        },
        any
      >({
        path: `/contest/past/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description List all problems of current contest
     *
     * @tags contest
     * @name ContestProblemsList
     * @request GET:/contest/{contest_id}/problems/
     * @secure
     */
    contestProblemsList: (
      contestId: string,
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ProblemRead[];
        },
        any
      >({
        path: `/contest/${contestId}/problems/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get info about a given problem from current contest.
     *
     * @tags contest
     * @name ContestProblemsRead
     * @request GET:/contest/{contest_id}/problems/{id}/
     * @secure
     */
    contestProblemsRead: (
      contestId: string,
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<ProblemRead, any>({
        path: `/contest/${contestId}/problems/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Re-run judge system to this Problem
     *
     * @tags contest
     * @name ContestProblemsRejudge
     * @request GET:/contest/{contest_id}/problems/{id}/rejudge/
     * @secure
     */
    contestProblemsRejudge: (
      contestId: string,
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<ProblemRead, any>({
        path: `/contest/${contestId}/problems/${id}/rejudge/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Reset a user token for a given contest
     *
     * @tags contest
     * @name ContestResetToken
     * @request POST:/contest/{contest_id}/reset_token/
     * @secure
     */
    contestResetToken: (
      contestId: string,
      data: ParticipantWrite,
      params: RequestParams = {}
    ) =>
      this.request<ParticipantWrite, any>({
        path: `/contest/${contestId}/reset_token/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint show standings of this Contest
     *
     * @tags contest
     * @name ContestStandingsList
     * @request GET:/contest/{contest_id}/standings/
     * @secure
     */
    contestStandingsList: (
      contestId: string,
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<Standings[], any>({
        path: `/contest/${contestId}/standings/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description List all submissions
     *
     * @tags contest
     * @name ContestSubmissionList
     * @request GET:/contest/{contest_id}/submission/
     * @secure
     */
    contestSubmissionList: (
      contestId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Submission[];
        },
        any
      >({
        path: `/contest/${contestId}/submission/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create submission override
     *
     * @tags contest
     * @name ContestSubmissionCreate
     * @request POST:/contest/{contest_id}/submission/
     * @secure
     */
    contestSubmissionCreate: (
      contestId: string,
      data: Submission,
      params: RequestParams = {}
    ) =>
      this.request<Submission, any>({
        path: `/contest/${contestId}/submission/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This ViewSet show all submissions of a given Contest
     *
     * @tags contest
     * @name ContestSubmissionRead
     * @request GET:/contest/{contest_id}/submission/{id}/
     * @secure
     */
    contestSubmissionRead: (
      contestId: string,
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<Submission, any>({
        path: `/contest/${contestId}/submission/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Re-run judge system to this Submission
     *
     * @tags contest
     * @name ContestSubmissionRejudge
     * @request GET:/contest/{contest_id}/submission/{id}/rejudge/
     * @secure
     */
    contestSubmissionRejudge: (
      contestId: string,
      id: number,
      params: RequestParams = {}
    ) =>
      this.request<Submission, any>({
        path: `/contest/${contestId}/submission/${id}/rejudge/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Contest ViewSet
     *
     * @tags contest
     * @name ContestRead
     * @request GET:/contest/{id}/
     * @secure
     */
    contestRead: (id: number, params: RequestParams = {}) =>
      this.request<ContestRead, void>({
        path: `/contest/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Contest ViewSet
     *
     * @tags contest
     * @name ContestUpdate
     * @request PUT:/contest/{id}/
     * @secure
     */
    contestUpdate: (
      id: number,
      data: ContestWrite,
      params: RequestParams = {}
    ) =>
      this.request<ContestWrite, any>({
        path: `/contest/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Contest ViewSet
     *
     * @tags contest
     * @name ContestPartialUpdate
     * @request PATCH:/contest/{id}/
     * @secure
     */
    contestPartialUpdate: (
      id: number,
      data: ContestWrite,
      params: RequestParams = {}
    ) =>
      this.request<ContestWrite, any>({
        path: `/contest/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Contest ViewSet
     *
     * @tags contest
     * @name ContestDelete
     * @request DELETE:/contest/{id}/
     * @secure
     */
    contestDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/contest/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  coursesubject = {
    /**
     * No description
     *
     * @tags coursesubject
     * @name CoursesubjectList
     * @request GET:/coursesubject/
     * @secure
     */
    coursesubjectList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: CourseSubject[];
        },
        any
      >({
        path: `/coursesubject/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags coursesubject
     * @name CoursesubjectCreate
     * @request POST:/coursesubject/
     * @secure
     */
    coursesubjectCreate: (data: CourseSubject, params: RequestParams = {}) =>
      this.request<CourseSubject, any>({
        path: `/coursesubject/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags coursesubject
     * @name CoursesubjectRead
     * @request GET:/coursesubject/{id}/
     * @secure
     */
    coursesubjectRead: (id: number, params: RequestParams = {}) =>
      this.request<CourseSubject, any>({
        path: `/coursesubject/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags coursesubject
     * @name CoursesubjectUpdate
     * @request PUT:/coursesubject/{id}/
     * @secure
     */
    coursesubjectUpdate: (
      id: number,
      data: CourseSubject,
      params: RequestParams = {}
    ) =>
      this.request<CourseSubject, any>({
        path: `/coursesubject/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags coursesubject
     * @name CoursesubjectPartialUpdate
     * @request PATCH:/coursesubject/{id}/
     * @secure
     */
    coursesubjectPartialUpdate: (
      id: number,
      data: CourseSubject,
      params: RequestParams = {}
    ) =>
      this.request<CourseSubject, any>({
        path: `/coursesubject/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags coursesubject
     * @name CoursesubjectDelete
     * @request DELETE:/coursesubject/{id}/
     * @secure
     */
    coursesubjectDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/coursesubject/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  dashboard = {
    /**
     * No description
     *
     * @tags dashboard
     * @name DashboardContestSubjectList
     * @request GET:/dashboard/contest/subject/
     * @secure
     */
    dashboardContestSubjectList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ContestSemester[];
        },
        any
      >({
        path: `/dashboard/contest/subject/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DashboardCountList
     * @request GET:/dashboard/count/
     * @secure
     */
    dashboardCountList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** subject_id */
        subject_id?: string;
        /** contest_id */
        contest_id?: string;
        /** semester */
        semester?: string;
        /** contest_type */
        contest_type?: "exam" | "training";
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<DashboardCount, any>({
        path: `/dashboard/count/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DashboardSubmissionsSemesterList
     * @request GET:/dashboard/submissions/semester/
     * @secure
     */
    dashboardSubmissionsSemesterList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** semester */
        semester?: string;
        /** subject_id */
        subject_id?: string;
        /** contest_id */
        contest_id?: string;
        /** contest_type */
        contest_type?: "exam" | "training";
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: SemesterSubmission[];
        },
        any
      >({
        path: `/dashboard/submissions/semester/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DashboardSubmissionsTagList
     * @request GET:/dashboard/submissions/tag/
     * @secure
     */
    dashboardSubmissionsTagList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** semester */
        semester?: string;
        /** subject_id */
        subject_id?: string;
        /** contest_id */
        contest_id?: string;
        /** contest_type */
        contest_type?: "exam" | "training";
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: SubmissionTag[];
        },
        any
      >({
        path: `/dashboard/submissions/tag/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags dashboard
     * @name DashboardSubmissionsTypeList
     * @request GET:/dashboard/submissions/type/
     * @secure
     */
    dashboardSubmissionsTypeList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** semester */
        semester?: string;
        /** subject_id */
        subject_id?: string;
        /** contest_id */
        contest_id?: string;
        /** contest_type */
        contest_type?: "exam" | "training";
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: SubmissionType[];
        },
        any
      >({
        path: `/dashboard/submissions/type/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  gradereport = {
    /**
     * No description
     *
     * @tags gradereport
     * @name GradereportList
     * @request GET:/gradereport/
     * @secure
     */
    gradereportList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: GradeReport[];
        },
        any
      >({
        path: `/gradereport/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags gradereport
     * @name GradereportCreate
     * @request POST:/gradereport/
     * @secure
     */
    gradereportCreate: (data: GradeReport, params: RequestParams = {}) =>
      this.request<GradeReport, any>({
        path: `/gradereport/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags gradereport
     * @name GradereportRead
     * @request GET:/gradereport/{id}/
     * @secure
     */
    gradereportRead: (id: number, params: RequestParams = {}) =>
      this.request<GradeReport, any>({
        path: `/gradereport/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags gradereport
     * @name GradereportUpdate
     * @request PUT:/gradereport/{id}/
     * @secure
     */
    gradereportUpdate: (
      id: number,
      data: GradeReport,
      params: RequestParams = {}
    ) =>
      this.request<GradeReport, any>({
        path: `/gradereport/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags gradereport
     * @name GradereportPartialUpdate
     * @request PATCH:/gradereport/{id}/
     * @secure
     */
    gradereportPartialUpdate: (
      id: number,
      data: GradeReport,
      params: RequestParams = {}
    ) =>
      this.request<GradeReport, any>({
        path: `/gradereport/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags gradereport
     * @name GradereportDelete
     * @request DELETE:/gradereport/{id}/
     * @secure
     */
    gradereportDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/gradereport/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  languages = {
    /**
     * @description Return a list of all languages.
     *
     * @tags languages
     * @name LanguagesList
     * @request GET:/languages/
     * @secure
     */
    languagesList: (params: RequestParams = {}) =>
      this.request<Languages, any>({
        path: `/languages/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  material = {
    /**
     * No description
     *
     * @tags material
     * @name MaterialList
     * @request GET:/material/
     * @secure
     */
    materialList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Material[];
        },
        any
      >({
        path: `/material/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags material
     * @name MaterialCreate
     * @request POST:/material/
     * @secure
     */
    materialCreate: (data: Material, params: RequestParams = {}) =>
      this.request<Material, any>({
        path: `/material/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags material
     * @name MaterialRead
     * @request GET:/material/{id}/
     * @secure
     */
    materialRead: (id: number, params: RequestParams = {}) =>
      this.request<Material, any>({
        path: `/material/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags material
     * @name MaterialUpdate
     * @request PUT:/material/{id}/
     * @secure
     */
    materialUpdate: (id: number, data: Material, params: RequestParams = {}) =>
      this.request<Material, any>({
        path: `/material/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags material
     * @name MaterialPartialUpdate
     * @request PATCH:/material/{id}/
     * @secure
     */
    materialPartialUpdate: (
      id: number,
      data: Material,
      params: RequestParams = {}
    ) =>
      this.request<Material, any>({
        path: `/material/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags material
     * @name MaterialDelete
     * @request DELETE:/material/{id}/
     * @secure
     */
    materialDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/material/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  password = {
    /**
     * @description An endpoint for change password in logged area.
     *
     * @tags password
     * @name PasswordChangeCreate
     * @request POST:/password/change/
     * @secure
     */
    passwordChangeCreate: (data: ChangePassword, params: RequestParams = {}) =>
      this.request<ChangePassword, any>({
        path: `/password/change/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description An endpoint for change password in logged area from the professor User.
     *
     * @tags password
     * @name PasswordResetCreate
     * @request POST:/password/reset/
     * @secure
     */
    passwordResetCreate: (
      data: ChangePasswordByProfessor,
      params: RequestParams = {}
    ) =>
      this.request<ChangePasswordByProfessor, any>({
        path: `/password/reset/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint send a email with link, to set the new password.
     *
     * @tags password
     * @name PasswordResetNewCreate
     * @request POST:/password/reset/new/
     * @secure
     */
    passwordResetNewCreate: (data: ResetPassword, params: RequestParams = {}) =>
      this.request<
        {
          message?: object;
        },
        any
      >({
        path: `/password/reset/new/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Request a form to fill the new password, if recovery token is valid. This request is called from link passed by e-mail
     *
     * @tags password
     * @name PasswordResetRead
     * @request GET:/password/reset/{recovery_token}/
     * @secure
     */
    passwordResetRead: (recoveryToken: string, params: RequestParams = {}) =>
      this.request<ResetPassword, any>({
        path: `/password/reset/${recoveryToken}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Receive password data in request and add to the current User. The recovery token in url is required.
     *
     * @tags password
     * @name PasswordResetCreate2
     * @request POST:/password/reset/{recovery_token}/
     * @originalName passwordResetCreate
     * @duplicate
     * @secure
     */
    passwordResetCreate2: (
      recoveryToken: string,
      data: CreateNewPassword,
      params: RequestParams = {}
    ) =>
      this.request<CreateNewPassword, any>({
        path: `/password/reset/${recoveryToken}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  problem = {
    /**
     * @description Problem CRUD
     *
     * @tags problem
     * @name ProblemList
     * @request GET:/problem/
     * @secure
     */
    problemList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** name */
        name?: string;
        /** tag */
        tag?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: BaseProblem[];
        },
        any
      >({
        path: `/problem/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Problem CRUD
     *
     * @tags problem
     * @name ProblemCreate
     * @request POST:/problem/
     * @secure
     */
    problemCreate: (data: BaseProblem, params: RequestParams = {}) =>
      this.request<BaseProblem, any>({
        path: `/problem/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Problem CRUD
     *
     * @tags problem
     * @name ProblemRead
     * @request GET:/problem/{id}/
     * @secure
     */
    problemRead: (id: number, params: RequestParams = {}) =>
      this.request<BaseProblem, any>({
        path: `/problem/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Problem CRUD
     *
     * @tags problem
     * @name ProblemPartialUpdate
     * @request PATCH:/problem/{id}/
     * @secure
     */
    problemPartialUpdate: (
      id: number,
      data: BaseProblem,
      params: RequestParams = {}
    ) =>
      this.request<BaseProblem, any>({
        path: `/problem/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Problem CRUD
     *
     * @tags problem
     * @name ProblemDelete
     * @request DELETE:/problem/{id}/
     * @secure
     */
    problemDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/problem/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Return a statement pdf file from the current problem
     *
     * @tags problem
     * @name ProblemStatementRead
     * @request GET:/problem/{problem_id}/statement/
     * @secure
     */
    problemStatementRead: (
      problemId: string,
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      data?: any,
      params: RequestParams = {}
    ) =>
      this.request<File, any>({
        path: `/problem/${problemId}/statement/`,
        method: "GET",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Update the statement of current problem
     *
     * @tags problem
     * @name ProblemStatementCreate
     * @request POST:/problem/{problem_id}/statement/
     * @secure
     */
    problemStatementCreate: (
      problemId: string,
      data: {
        /**
         * A PDF file with statement of this problem
         * @format binary
         */
        statement: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<Statement, any>({
        path: `/problem/${problemId}/statement/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Return a testcase zip file from the current problem.
     *
     * @tags problem
     * @name ProblemTestcaseRead
     * @request GET:/problem/{problem_id}/testcase/
     * @secure
     */
    problemTestcaseRead: (
      problemId: string,
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      data?: any,
      params: RequestParams = {}
    ) =>
      this.request<File, any>({
        path: `/problem/${problemId}/testcase/`,
        method: "GET",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Update the testcase of current problem
     *
     * @tags problem
     * @name ProblemTestcaseCreate
     * @request POST:/problem/{problem_id}/testcase/
     * @secure
     */
    problemTestcaseCreate: (
      problemId: string,
      data: {
        /**
         * Submit a zip file with testscase of this problem
         * @format binary
         */
        testscase: File;
      },
      params: RequestParams = {}
    ) =>
      this.request<Testcase, any>({
        path: `/problem/${problemId}/testcase/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  testcaseResult = {
    /**
     * @description List TestcaseResults
     *
     * @tags testcaseResult
     * @name TestcaseResultList
     * @request GET:/testcase-results/
     * @secure
     */
    testcaseResultList: (
      query?: {
        submission?: number;
        problem?: number;
        participant?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<TestcaseResultList, any>({
        path: `/testcase-results/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create TestcaseResult
     *
     * @tags testcaseResult
     * @name TestcaseResultCreate
     * @request POST:/testcase-results/
     * @secure
     */
    testcaseResultCreate: (
      data: TestcaseResultCreate,
      params: RequestParams = {}
    ) =>
      this.request<TestcaseResult, any>({
        path: `/testcase-results/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete TestcaseResult
     *
     * @tags testcaseResult
     * @name TestcaseResultDelete
     * @request DELETE:/testcase-results/{id}/
     * @secure
     */
    testcaseResultDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/testcase-results/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  sessionlog = {
    /**
     * No description
     *
     * @tags sessionlog
     * @name SessionlogList
     * @request GET:/sessionlog/
     * @secure
     */
    sessionlogList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: JudeSessionLog[];
        },
        any
      >({
        path: `/sessionlog/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags sessionlog
     * @name SessionlogRead
     * @request GET:/sessionlog/{id}/
     * @secure
     */
    sessionlogRead: (id: number, params: RequestParams = {}) =>
      this.request<JudeSessionLog, any>({
        path: `/sessionlog/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  similarity = {
    /**
     * @description Check similarity from all submissions of the Contest
     *
     * @tags similarity
     * @name SimilarityProblemList
     * @request GET:/similarity/{contest_id}/problem/{problem_id}/
     * @secure
     */
    similarityProblemList: (
      contestId: string,
      problemId: string,
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
        /** A flag to force the execution of the Sherlock task. */
        force_execution?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<Similarity[], any>({
        path: `/similarity/${contestId}/problem/${problemId}/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Return a detail of two participants from the given contest
     *
     * @tags similarity
     * @name SimilarityProblemCompare
     * @request GET:/similarity/{contest_id}/problem/{problem_id}/compare/
     * @secure
     */
    similarityProblemCompare: (
      contestId: string,
      problemId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
        /** ID of similarity object */
        id?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<SimilarityProblem, any>({
        path: `/similarity/${contestId}/problem/${problemId}/compare/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Confirm if the profesor will accepted or reject the Similarity
     *
     * @tags similarity
     * @name SimilarityProblemConfirm
     * @request POST:/similarity/{contest_id}/problem/{problem_id}/confirm/
     * @secure
     */
    similarityProblemConfirm: (
      contestId: string,
      problemId: string,
      data: SimilarityConfirm,
      params: RequestParams = {}
    ) =>
      this.request<SimilarityConfirm, any>({
        path: `/similarity/${contestId}/problem/${problemId}/confirm/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Run the plagiarism detector for all participants of the contest
     *
     * @tags similarity
     * @name SimilarityProblemRun
     * @request GET:/similarity/{contest_id}/problem/{problem_id}/run/
     * @secure
     */
    similarityProblemRun: (
      contestId: string,
      problemId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/similarity/${contestId}/problem/${problemId}/run/`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  submission = {
    /**
     * No description
     *
     * @tags submission
     * @name SubmissionList
     * @request GET:/submission/
     * @secure
     */
    submissionList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** contest */
        contest?: string;
        /** username */
        username?: string;
        /** problem */
        problem?: string;
        /** language */
        language?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Submission[];
        },
        any
      >({
        path: `/submission/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags submission
     * @name SubmissionRead
     * @request GET:/submission/{id}/
     * @secure
     */
    submissionRead: (id: number, params: RequestParams = {}) =>
      this.request<Submission, any>({
        path: `/submission/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  tag = {
    /**
     * No description
     *
     * @tags tag
     * @name TagList
     * @request GET:/tag/
     * @secure
     */
    tagList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** name */
        name?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Tag[];
        },
        any
      >({
        path: `/tag/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag
     * @name TagCreate
     * @request POST:/tag/
     * @secure
     */
    tagCreate: (data: Tag, params: RequestParams = {}) =>
      this.request<Tag, any>({
        path: `/tag/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag
     * @name TagRead
     * @request GET:/tag/{id}/
     * @secure
     */
    tagRead: (id: number, params: RequestParams = {}) =>
      this.request<Tag, any>({
        path: `/tag/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag
     * @name TagUpdate
     * @request PUT:/tag/{id}/
     * @secure
     */
    tagUpdate: (id: number, data: Tag, params: RequestParams = {}) =>
      this.request<Tag, any>({
        path: `/tag/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag
     * @name TagPartialUpdate
     * @request PATCH:/tag/{id}/
     * @secure
     */
    tagPartialUpdate: (id: number, data: Tag, params: RequestParams = {}) =>
      this.request<Tag, any>({
        path: `/tag/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags tag
     * @name TagDelete
     * @request DELETE:/tag/{id}/
     * @secure
     */
    tagDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/tag/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };

  semester = {
    /**
     * No description
     *
     * @tags semester
     * @name SemesterList
     * @request GET:/semester/
     * @secure
     */
    semesterList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Semester[];
        },
        any
      >({
        path: `/semester/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags semester
     * @name SemesterCreate
     * @request POST:/semester/
     * @secure
     */
    semesterCreate: (data: Semester, params: RequestParams = {}) =>
      this.request<Semester, any>({
        path: `/semester/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags semester
     * @name SemesterRead
     * @request GET:/semester/{id}/
     * @secure
     */
    semesterRead: (id: number, params: RequestParams = {}) =>
      this.request<Semester, any>({
        path: `/semester/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags semester
     * @name SemesterUpdate
     * @request PUT:/semester/{id}/
     * @secure
     */
    semesterUpdate: (id: number, data: Semester, params: RequestParams = {}) =>
      this.request<Semester, any>({
        path: `/semester/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags semester
     * @name SemesterPartialUpdate
     * @request PATCH:/semester/{id}/
     * @secure
     */
    semesterPartialUpdate: (id: number, data: Semester, params: RequestParams = {}) =>
      this.request<Semester, any>({
        path: `/semester/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags semester
     * @name SemesterDelete
     * @request DELETE:/semester/{id}/
     * @secure
     */
    semesterDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/semester/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  token = {
    /**
     * @description Returns a JSON Web Token that can be used for authenticated requests.
     *
     * @tags token
     * @name TokenAuthCreate
     * @summary API View that receives a POST with a user's username and password.
     * @request POST:/token/auth/
     * @secure
     */
    tokenAuthCreate: (data: JSONWebToken, params: RequestParams = {}) =>
      this.request<JSONWebToken, any>({
        path: `/token/auth/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
 * @description If 'orig_iat' field (original issued-at-time) is found it will first check if it's within expiration window, then copy it to the new token.
 *
 * @tags token
 * @name TokenRefreshAuthCreate
 * @summary API View that returns a refreshed token (with new expiration) based on
existing token
 * @request POST:/token/refresh-auth/
 * @secure
 */
    tokenRefreshAuthCreate: (
      data: RefreshAuthToken,
      params: RequestParams = {}
    ) =>
      this.request<RefreshAuthToken, any>({
        path: `/token/refresh-auth/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserList
     * @request GET:/user/
     * @secure
     */
    userList: (
      query?: {
        /** A search term. */
        search?: string;
        /** Which field to use when ordering the results. */
        ordering?: string;
        /** username */
        username?: string;
        /** id */
        id?: string;
        /** is_staff */
        is_staff?: string;
        /** first_name */
        first_name?: string;
        /** last_name */
        last_name?: string;
        /** email */
        email?: string;
        /** A page number within the paginated result set. */
        page?: number;
        /** Number of results to return per page. */
        page_size?: number;
        // 'professor' | 'contestant' | 'staff' | 'superuser'
        role?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: User[];
        },
        any
      >({
        path: `/user/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserCreate
     * @request POST:/user/
     * @secure
     */
    userCreate: (data: User, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/user/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserCheckSurveyAnswer
     * @request POST:/user/check_survey_answer/
     * @secure
     */
    userCheckSurveyAnswer: (
      data: CheckSurveyAnswer,
      params: RequestParams = {}
    ) =>
      this.request<HasSurveyAnswer, any>({
        path: `/user/check_survey_answer/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserRead
     * @request GET:/user/{username}/
     * @secure
     */
    userRead: (username: string, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/user/${username}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserRead
     * @request GET:/user/{username}/
     * @secure
     */
    checkstaff: (params: RequestParams = {}) =>
      this.request<{ is_staff: boolean }, any>({
        path: `/check-staff/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserRead
     * @request GET:/user/{username}/
     * @secure
     */
    checkcontestant: (params: RequestParams = {}) =>
      this.request<{ is_contestant: boolean }, any>({
        path: `/check-contestant/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserRead
     * @request GET:/user/{username}/
     * @secure
     */
    checksuperuser: (params: RequestParams = {}) =>
      this.request<{ is_superuser: boolean }, any>({
        path: `/check-superuser/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserRead
     * @request GET:/user/{username}/
     * @secure
     */
    checkprofessor: (params: RequestParams = {}) =>
      this.request<{ is_professor: boolean }, any>({
        path: `/check-professor/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserUpdate
     * @request PUT:/user/{username}/
     * @secure
     */
    userUpdate: (username: string, data: User, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/user/${username}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserPartialUpdate
     * @request PATCH:/user/{username}/
     * @secure
     */
    userPartialUpdate: (
      username: string,
      data: User,
      params: RequestParams = {}
    ) =>
      this.request<User, any>({
        path: `/user/${username}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description API endpoint that allows users to be viewed or edited.
     *
     * @tags user
     * @name UserDelete
     * @request DELETE:/user/{username}/
     * @secure
     */
    userDelete: (username: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/${username}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  userbatch = {
    /**
     * @description Example of input in front end username, email [, first_name, last_name];
     *
     * @tags userbatch
     * @name UserbatchCreate
     * @summary The request data need the list of usernames and emails to create Users.
     * @request POST:/userbatch/
     * @secure
     */
    userbatchCreate: (data: UserBatch, params: RequestParams = {}) =>
      this.request<UserBatch, any>({
        path: `/userbatch/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
